var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Item } from 'rc-menu';
import toArray from 'rc-util/lib/Children/toArray';
import classNames from 'classnames';
import MenuContext from './MenuContext';
import Tooltip from '../tooltip';
import { SiderContext } from '../layout/Sider';
import { isValidElement } from '../_util/reactNode';
export default class MenuItem extends React.Component {
    constructor() {
        super(...arguments);
        this.renderItem = ({ siderCollapsed }) => {
            const { level, className, children, rootPrefixCls } = this.props;
            const _a = this.props, { title, icon, danger } = _a, rest = __rest(_a, ["title", "icon", "danger"]);
            return (<MenuContext.Consumer>
        {({ inlineCollapsed, direction }) => {
                let tooltipTitle = title;
                if (typeof title === 'undefined') {
                    tooltipTitle = level === 1 ? children : '';
                }
                else if (title === false) {
                    tooltipTitle = '';
                }
                const tooltipProps = {
                    title: tooltipTitle,
                };
                if (!siderCollapsed && !inlineCollapsed) {
                    tooltipProps.title = null;
                    // Reset `visible` to fix control mode tooltip display not correct
                    // ref: https://github.com/ant-design/ant-design/issues/16742
                    tooltipProps.visible = false;
                }
                const childrenLength = toArray(children).length;
                return (<Tooltip {...tooltipProps} placement={direction === 'rtl' ? 'left' : 'right'} overlayClassName={`${rootPrefixCls}-inline-collapsed-tooltip`}>
              <Item {...rest} className={classNames(className, {
                    [`${rootPrefixCls}-item-danger`]: danger,
                    [`${rootPrefixCls}-item-only-child`]: (icon ? childrenLength + 1 : childrenLength) === 1,
                })} title={title}>
                {icon}
                {this.renderItemChildren(inlineCollapsed)}
              </Item>
            </Tooltip>);
            }}
      </MenuContext.Consumer>);
        };
    }
    renderItemChildren(inlineCollapsed) {
        const { icon, children, level, rootPrefixCls } = this.props;
        // inline-collapsed.md demo 依赖 span 来隐藏文字,有 icon 属性，则内部包裹一个 span
        // ref: https://github.com/ant-design/ant-design/pull/23456
        if (!icon || (isValidElement(children) && children.type === 'span')) {
            if (children && inlineCollapsed && level === 1 && typeof children === 'string') {
                return (<div className={`${rootPrefixCls}-inline-collapsed-noicon`}>{children.charAt(0)}</div>);
            }
            return children;
        }
        return <span>{children}</span>;
    }
    render() {
        return <SiderContext.Consumer>{this.renderItem}</SiderContext.Consumer>;
    }
}
MenuItem.isMenuItem = true;
