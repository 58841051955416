import * as React from 'react';
import omit from 'omit.js';
import RcSteps from 'rc-steps';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
import { ConfigConsumer } from '../config-provider';
import Progress from '../progress';
export default class Steps extends React.Component {
    constructor() {
        super(...arguments);
        this.renderSteps = ({ getPrefixCls, direction }) => {
            const prefixCls = getPrefixCls('steps', this.props.prefixCls);
            const iconPrefix = getPrefixCls('', this.props.iconPrefix);
            const { percent, size } = this.props;
            const className = classNames(this.props.className, {
                [`${prefixCls}-rtl`]: direction === 'rtl',
            });
            const icons = {
                finish: <CheckOutlined className={`${prefixCls}-finish-icon`}/>,
                error: <CloseOutlined className={`${prefixCls}-error-icon`}/>,
            };
            const stepIconRender = ({ node, status, }) => {
                if (status === 'process' && percent !== undefined) {
                    // currently it's hard-coded, since we can't easily read the actually width of icon
                    const progressWidth = size === 'small' ? 32 : 40;
                    const iconWithProgress = (<div className={`${prefixCls}-progress-icon`}>
            <Progress type="circle" percent={percent} width={progressWidth} strokeWidth={4} format={() => null}/>
            {node}
          </div>);
                    return iconWithProgress;
                }
                return node;
            };
            return (<RcSteps icons={icons} {...omit(this.props, ['progress'])} stepIcon={stepIconRender} prefixCls={prefixCls} iconPrefix={iconPrefix} className={className}/>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderSteps}</ConfigConsumer>;
    }
}
Steps.Step = RcSteps.Step;
Steps.defaultProps = {
    current: 0,
};
